interface Option {
  whitelist: string[];
  blacklist: string[];
  [prop: string]: any;
}

export const getOptionFromList = (options: Option[], answers: string[]) => {
  let typeOptionIndex = options?.findIndex(opt => {
    const passedWhitelist = opt.whitelist.some((w: string) =>
      answers.some(a => a === w)
    );
    const passedBlacklist = opt.blacklist.some((w: string) =>
      answers.some(a => a === w)
    );
    return passedWhitelist && !passedBlacklist;
  });
  // if options don't pass whitelist & !blacklist, fallback to last option
  if (typeOptionIndex === -1) {
    typeOptionIndex = options.length - 1;
  }
  return options?.[typeOptionIndex];
};

export const formatCost = (num: number) => {
  // formats number to locale string eg. 12700 -> 12,720.00
  const localeString = (Math.round(num * 100) / 100).toLocaleString('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
  // replaces formatted number to custom format eg. 12,720.00 -> 12 720,00
  return localeString.replace(',', ' ').replace('.', ',');
};

export const getUnixDate = () => Math.floor(new Date().getTime() / 1000);
