import { config } from '../config';
import { AnalyticsClass } from './analytics';

import { ConversionsAPIEventNames } from './conversionAPI/types/conversionAPI';
import { Product } from '../types/product';
import { getLocalisedProduct } from './localization';
import {
  convertKiloProductToGAItem,
  convertKiloProductToGAItemMain,
} from './googleAnalytics/helpers';
import { postToConversionsAPI } from './conversionAPI/conversionAPI';
import { GAPageView, GAQuestionAnswer } from 'types/analyticsTypes';

enum TrackingEvents {
  QuizStarted = 'quiz_started',
  ViewItemList = 'view_item_list',
}

const generateEventId = () => {
  const timestamp = new Date().getTime();
  const randomNumber = Math.floor(Math.random() * 10000); // Adjust the upper limit as needed

  return `${timestamp}${randomNumber}`;
};

export class TrackingUtil {
  private readonly enabled: boolean = false;
  private readonly analytics: AnalyticsClass;
  private readonly firedEvents: string[] = [];

  constructor() {
    this.enabled = config.TRACKING_ENABLED;
    this.analytics = new AnalyticsClass(this.enabled);
  }

  leadCreated = (
    code: string,
    email: string,
    country_code: string,
    gender: string,
    state: string,
    userIp: string
  ) => {
    const eventID = generateEventId();
    this.analytics.clearQuizData();
    this.analytics.trackUserCodeGenerated(
      code,
      email,
      country_code?.toLocaleLowerCase(),
      state?.toLocaleLowerCase(),
      gender,
      eventID
    );

    postToConversionsAPI(
      ConversionsAPIEventNames.Lead,
      {
        email: email,
        country: country_code?.toLocaleLowerCase(),
        gender: gender,
        state: state?.toLocaleLowerCase(),
      },
      {},
      {},
      code,
      eventID,
      userIp
    );
  };

  initiateCheckout = (
    code: string,
    email: string,
    products: Product[],
    country_code: string,
    state: string,
    gender: string,
    city: string,
    userIp: string
  ) => {
    const eventID = generateEventId();

    this.analytics.trackCheckoutInitiated(code, email, products, eventID);

    postToConversionsAPI(
      ConversionsAPIEventNames.InitiateCheckout,
      {
        email: email,
        country: country_code?.toLocaleLowerCase(),
        state: state?.toLocaleLowerCase(),
        gender: gender,
        city: city,
      },
      {},
      {},
      code,
      eventID,
      userIp
    );
  };

  trackCTAButton = (url: string) => {
    const eventID = generateEventId();

    this.analytics.trackCTAButton(url, eventID);
  };

  trackRegistration = (code: string) => {
    const eventID = generateEventId();

    this.analytics.trackRegistration(code, eventID);
  };

  trackQuizQuestionAnswered = (data: GAQuestionAnswer) => {
    const eventID = generateEventId();

    this.analytics.trackQuizQuestionAnswered(data, eventID);
  };

  logEvent = (data: any, optionalData?: GAPageView) => {
    const eventID = generateEventId();

    this.analytics.logEvent(
      { ...data, ...optionalData },
      eventID,
      optionalData?.client_code
    );

    // postToConversionsAPI(
    //   ConversionsAPIEventNames.PageView,
    //   {
    //     ...optionalData,
    //   },
    //   {},
    //   {},
    //   optionalData?.client_code,
    //   eventID,
    //   userIp,
    // );
  };

  virtualPageView = (data: any, optionalData?: GAPageView, userIp?: string) => {
    const eventID = generateEventId();

    this.analytics.virtualPageView(
      { ...data, ...optionalData },
      eventID,
      optionalData?.client_code
    );

    postToConversionsAPI(
      ConversionsAPIEventNames.PageView,
      {
        ...optionalData,
      },
      {},
      {},
      optionalData?.client_code,
      eventID,
      userIp
    );
  };

  initiatePurchase = (
    email: string,
    code: string,
    orderId: number,
    product: Product,
    country_code: string,
    state: string,
    gender: string,
    userIp: string
  ) => {
    const eventID = generateEventId();

    const gaItem = getLocalisedProduct(product);

    const price = Number(
      gaItem?.hasTrial
        ? gaItem?.discountedTrialPrice || 0
        : gaItem?.finalPrice || 0
    ).toFixed(2);

    postToConversionsAPI(
      ConversionsAPIEventNames.Purchase,
      {
        email: email,
        gender: gender?.toLowerCase(),
        country: country_code?.toLocaleLowerCase(),
        state: state?.toLocaleLowerCase(),
      },
      {
        currency: gaItem?.currencyId,
        value: price,
      },
      {
        currency: gaItem?.currencyId,
        value: price,
      },
      code,
      eventID,
      userIp
    );

    this.analytics.clearItems();

    const gaItemList = convertKiloProductToGAItem(product, 'initial');

    const purchaseInitiatedParams = {
      email: email,
      transaction_id: String(orderId),
      purchase_type: 'initial',
      user_id: code,
      currency: gaItem?.currencyId,
      value: price,
      items: [gaItemList],
    };

    const mainGaItem = convertKiloProductToGAItemMain(product);

    const mainPurchaseInitiatedParams = {
      user_id: code,
      email: email,
      currencyCode: gaItem?.currencyId || 'no-currency_code-fallback',
      transactionId: orderId,
      transactionTotal: price,
      transactionProducts: [mainGaItem],
    };

    this.analytics.clearEcommerce();

    this.analytics.trackPurchaseInitiated(
      purchaseInitiatedParams,
      'initial',
      eventID,
      code
    );
    this.analytics.clearEcommerce();
    this.analytics.trackMainPurchaseInitiated(
      mainPurchaseInitiatedParams,
      eventID,
      code
    );
  };

  initiateUpsellPurchase = ({
    orderId,
    email,
    code,
    product,
  }: {
    orderId: string;
    email: string;
    code: string;
    product: Product;
  }) => {
    const eventID = generateEventId();

    const gaItem = convertKiloProductToGAItem(product, 'upsell');

    const price = Number(gaItem?.price?.toFixed(2)) ?? 0;
    const { currencyId } = getLocalisedProduct(product);

    const purchaseInitiatedParams = {
      email: email,
      transaction_id: String(orderId),
      user_id: code,
      currency: currencyId,
      value: price,
      items: [gaItem],
    };

    this.analytics.clearEcommerce();
    this.analytics.trackPurchaseUpsellInitiated(
      purchaseInitiatedParams,
      eventID,
      code
    );
    this.analytics.trackPurchaseInitiated(
      purchaseInitiatedParams,
      'upsell',
      eventID,
      code
    );
  };

  trackPaymentSubmit = (method: string, orderName: string) => {
    const eventID = generateEventId();

    this.analytics.trackPaymentSubmit(method, orderName, eventID);
  };
}

const Tracking = new TrackingUtil();

export default Tracking;
