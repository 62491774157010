import React, { FC, useMemo } from 'react';
import { config } from 'config';
import { AnalyticsClass } from '@frontend/analytics-wrapper';

export const AnalyticsContext = React.createContext<AnalyticsClass | null>(
  null
);

export const AnalyticsProvider: FC<any> = ({ children }) => {
  const analytics = useMemo(() => new AnalyticsClass(''), []);

  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
};
