import storageSession from 'redux-persist/lib/storage/session';
import * as Sentry from '@sentry/react';
import thunk, { ThunkDispatch, ThunkMiddleware } from 'redux-thunk';
import { persistReducer, persistStore } from 'redux-persist';
import { AnyAction, applyMiddleware, compose, createStore, Store } from 'redux';
import { RootStateOrAny } from 'react-redux';

import { config, ENVs } from 'config';
import { reactotron } from 'utils/reactotron';
import { Quiz, QuizKey } from 'types/quiz';

import { rootReducer } from './reducers';
import { fetchProducts, fetchQuiz, updateExperiments } from './funnel/effects';
import { fetchProjectConfig } from './config/effects';
import { fetchGeolocation, fetchUser } from './user/effects';
import { fetchPaymentGateway } from './payment/effects';
import { setLoading } from './funnel/actions';

const persistConfig = {
  key: config.API_TOKEN,
  storage: storageSession,
  blacklist: ['config'],
};

export const configStore = (preloadedState = {}) => {
  let store: Store<any, any>;
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;
  /* eslint-enable */
  const enhancers =
    typeof window !== 'undefined' && reactotron
      ? compose(reactotron?.createEnhancer!())
      : compose;

  if (config.ENV === ENVs.development) {
    store = createStore(
      persistedReducer,
      preloadedState,
      composeEnhancers(
        applyMiddleware(thunk as ThunkMiddleware<RootStateOrAny, AnyAction>),
        enhancers
      )
    );
  } else {
    const sentryReduxEnhancer = Sentry.createReduxEnhancer();
    store = createStore(
      persistedReducer,
      compose(
        applyMiddleware(thunk as ThunkMiddleware<RootStateOrAny, AnyAction>),
        sentryReduxEnhancer
      )
    );
  }

  const quiz =
    typeof window !== 'undefined'
      ? new URLSearchParams(location.search).get(QuizKey.Qz)
      : null;

  const persistor = persistStore(store, null, () => {
    store.dispatch(
      async (dispatch: ThunkDispatch<RootStateOrAny, void, AnyAction>) => {
        await dispatch(fetchGeolocation());
        dispatch(fetchProjectConfig(quiz || Quiz.Main));
        dispatch(fetchPaymentGateway());
        dispatch(fetchProducts());
        dispatch(fetchQuiz(quiz || Quiz.Main));
        dispatch(fetchUser());
        dispatch(updateExperiments());
      }
    );
  });

  return { store, persistor };
};
